<template>
    <div v-loading.fullscreen.lock="loading" class="login-wrapper custom-login-wrapper">
        <the-header/>
        <div class="user-details-card bg-blur resp_full-widths-user-d width40 mobile-width user-details-card-inner-wrapper" v-if="currentStep == 0">
            <div class="pricing-details-title customTitle  ">
                Not a member, <a href="https://www.oregonrla.org/membership.html" target="_blank">Click here</a> to join
            </div>

            <div class="mb-4 ">
                <input type="checkbox" value="memeber-details" v-model="already_member"/>Already a member?
            </div>
            <!-- <div class="input-item my-3" v-if="already_member">
                <label>Member ID: <span class="text-danger">*</span></label>
                <input type="text" placeholder="Enter your Member ID" v-model="member_id"/>
            </div> -->
                <div class="responsive-custom-table">

                    <div class="pricing-table not-pricing-table ">
                        <div class="pricing-table-heading">Select your Learning Paths and Courses below</div>
                    </div>

                    <div class="pricing-table not-pricing-table customPricing_Table" v-for="(courseFolder, index) in coursefolders" :key="`coursefolder-${index}`">
                        <div class="course-name">
                            <input type="checkbox" :value="courseFolder.id" v-model="checked_coursefolders" @change="courseFolderSelected"/>
                            <div>
                                {{ courseFolder.coursefolder_name }}
                                <div class="info-text">
                                    <img src="/orla/info.png" alt=""/>
                                    <div class="course-info">
                                        <ul class="info-list">
                                            <li v-for="(attachedCourses, index) in courseFolder.attachedCourses" :key="index">{{ attachedCourses.name }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pricing-table not-pricing-table customPricing_Table" v-for="(currentCourse, index) in course" :key="`course-${index}`">
                        <div class="course-name">
                            <input type="checkbox" :value="currentCourse.id" v-model="checked_courses" @change="courseSelected"/>
                            <div>
                                {{ currentCourse.course_name }}
                            </div>
                        </div>
                    </div>
                </div>

            <div class="pricing-details-title mt-4 leftAlign">
                <food-handler/>
            </div>

            <div class="step-buttons justify-content-center text-center">
                <button class="previous-button" type="button" @click="previousStep">
                    Previous
                </button>
                <button class="continue-button btn-align" type="button" @click="checkifOrlamember">
                    Continue
                    <loading-icon :loading="loading"></loading-icon>
                </button>
            </div>
        </div>

        <div class="user-details-card bg-blur companyDetailsCont" v-if="currentStep == 1">
            <div class="user-details-title">User Details</div>
            <div class="user-input-container">
                <div class="input-item">
                    <label>Company Name <span class="text-danger">*</span></label>
                    <input type="text" placeholder="Enter Your Company Name" v-model="company.name"/>
                    <span v-if="companyErrorsStep1['name'] == true" class="text-danger text-sm">The Company Name field is required</span>
                </div>
                <div class="input-item">
                    <label>First Name <span class="text-danger">*</span></label>
                    <input type="text" placeholder="Enter Your First Name" v-model="company.first_name"/>
                    <span v-if="companyErrorsStep1['first_name'] == true" class="text-danger text-sm">The First Name field is required</span>
                </div>
                <div class="input-item">
                    <label>Last Name <span class="text-danger">*</span></label>
                    <input type="text" placeholder="Enter Your Last Name" v-model="company.last_name"/>
                    <span v-if="companyErrorsStep1['last_name'] == true" class="text-danger text-sm">The Last Name field is required</span>
                </div>
                <div class="input-item">
                    <label>Email <span class="text-danger">*</span></label>
                    <input type="email" placeholder="Enter Your Email" v-model="company.email"/>
                    <span v-if="companyErrorsStep1['email'] == true" class="text-danger text-sm">The Email field is required</span>
                </div>
                <div class="input-item">
                    <label>Password <span class="text-danger">*</span></label>
                    <input type="password" placeholder="Enter Your Password" v-model="company.password"/>
                    <span v-if="companyErrorsStep1['password'] == true" class="text-danger text-sm">The Password field is required</span>
                </div>
                <div class="input-item">
                    <label>Phone <span class="text-danger">*</span></label>
                    <input type="text" placeholder="Enter Your Phone Number" v-model="company.telephone_no" @input="acceptNumber"/>
                    <span v-if="companyErrorsStep1['telephone_no'] == true" class="text-danger text-sm">The Phone field is required</span>
                </div>
                <div class="input-item">
                    <label># of Locations <span class="text-danger">*</span></label>
                    <input type="number" placeholder="Enter the # of Locations" v-model="company.no_of_locations"/>
                    <span v-if="companyErrorsStep1['no_of_locations'] == true" class="text-danger text-sm">The # of Locations field is required</span>
                </div>
                <div class="input-item">
                    <label>Estimated # of Users<span class="text-danger">*</span></label>
                    <input type="number" placeholder="Enter the # of Users" v-model="company.no_of_employees"/>
                    <span v-if="companyErrorsStep1['no_of_employees'] == true" class="text-danger text-sm">The Estimated # of Users field is required</span>
                </div>
            </div>
            <div class="step-buttons">
                <button class="previous-button" type="button" @click="previousStep">
                    Previous
                </button>
                <button class="continue-button btn-align" type="button" @click="nextStep">
                    Continue
                    <loading-icon :loading="loading"></loading-icon>
                </button>
            </div>
        </div>

        <div class="two_box-parent-blur reviewOrderPage" v-if="currentStep == 2">
            <div class="bg-blur half_width-child-div">
                <div class="user-details-title heading">Review Your Order</div>

                <div class="responsive-custom-table">
                    <div class="pricing-table">
                        <div class="pricing-table-heading">Course Selection</div>
                    </div>
                    <div class="pricing-table" v-for="(item, index) in selectedItems" :key="index">
                        <div class="course-name">
                            {{ item.name }}
                        </div>
                        <div class="btn_div">
                            <button class="" type="button" @click="removeCourse(item)">
                                <i class="fa fa-trash"></i>
                                <!-- {{ item.id }} -->
                            </button>
                        </div>
                    </div>
                </div>

                <div class="responsive-custom-table">
                    <div class="pricing-table">
                        <div class="pricing-table-heading">Company Details</div>
                    </div>
                    <div class="pricing-table">
                        <div class="course-name">
                            Location(s)
                        </div>
                        <div class="price-div">{{ locations_count }}</div>
                    </div>
                    <div class="pricing-table">
                        <div class="course-name">
                            User(s)
                        </div>
                        <div class="price-div">{{ employees_count }}</div>
                    </div>
                </div>
            </div>
            <div class="bg-blur half_width-child-div">
                <div class="user-details-title heading">Pricing</div>

                <div class="responsive-custom-table">
                    <div class="pricing-table" v-if="checked_coursefolders.length !== 0">
                        <div class="course-name">
                            Total Cost Per Month
                        </div>
                        <div class="price-div">{{ formatPrice(total_cost) }}</div>
                    </div>
                    <div class="pricing-table">
                        <div class="course-name" v-if="checked_coursefolders.length !== 0">
                            Price if paid in full for the year
                        </div>
                        <div v-else>
                            Total Cost
                        </div>
                        <div class="price-div">{{ formatPrice(perYearCost) }}</div>
                    </div>
                    <div class="pricing-table" v-if="discountPercentage">
                        <div class="course-name">
                            Discount Applied
                        </div>
                        <div class="price-div">{{ discountPercentage }}% off</div>
                    </div>
                </div>

                <div class="btn_promo-one-line customPromo_codeBox">
                    <div class="step-buttons" v-if="isMoreCompanyFiledsVisible === false">
                        <button class="previous-button" type="button" @click="previousStep">
                            Previous
                        </button>
                        <button class="continue-button" type="button" @click="showMoreCompanyFields">
                            Continue Signup
                            <loading-icon :loading="loading"></loading-icon>
                        </button>
                    </div>

                    <div class="promoCode_box">
                        <div class="promocode mt-2 font-weight-bold d-flex flex-column">
                            <label>Promo Code</label>
                            <input type="text" placeholder="Enter your Promo Code" v-model="promocode"/>
                        </div>
                        <button class="continue-button greenBG" type="button" @click="applyPromoCode">Apply Promocode</button>
                    </div>
                </div>
            </div>

            <div v-if="isMoreCompanyFiledsVisible === true" class="fullwidth_box-parents bg-blur">
                <div class="user-input-container two_grid_column">
                    <div class="input-item">
                        <label>Company Type</label>
                        <select v-model="company.company_type">
                            <option value="" selected disabled>Company Type</option>
                            <option :value="company_type.id" v-for="(company_type, index) in company_types" :key="index">{{ company_type.type }}</option>
                        </select>
                    </div>
                    <div class="input-item">
                        <label>Website</label>
                        <input type="text" placeholder="Enter Your Company Website's address" v-model="company.website"/>
                    </div>
                    <div class="input-item">
                        <label>Address <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Enter Your Address" v-model="company.address_1"/>
                        <span v-if="companyErrorsStep2['address_1'] == true" class="text-danger text-sm">The Address field is required</span>
                    </div>
                    <div class="input-item">
                        <label>City <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Enter Your City" v-model="company.city"/>
                        <span v-if="companyErrorsStep2['city'] == true" class="text-danger text-sm">The City field is required</span>
                    </div>
                    <div class="input-item">
                        <label>State <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Enter Your State" v-model="company.state"/>
                        <span v-if="companyErrorsStep2['state'] == true" class="text-danger text-sm">The State field is required</span>
                    </div>
                    <div class="input-item">
                        <label>Zip Code <span class="text-danger">*</span></label>
                        <input type="number" placeholder="Enter Your Zip Code" v-model="company.zip"/>
                        <span v-if="companyErrorsStep2['zip'] == true" class="text-danger text-sm">The Zip Code field is required</span>
                    </div>
                </div>

                <div class="step-buttons">
                    <button class="previous-button" type="button" @click="previousStep">
                        Previous
                    </button>
                    <button class="continue-button" type="button" @click="signupAgreement">
                        Continue
                        <loading-icon :loading="loading"></loading-icon>
                    </button>
                </div>

            </div>
        </div>
        <!-- <div class="user-details-card width_min-card_user bg-blur row">

        </div> -->

        <modal :show.sync="agreementModal" class="user-modal">
            <h3 slot="header">Service Activation Agreement</h3>
            <form>
                <div class="agreement-content">
                    <agreement type="company"></agreement>
                </div>
                <div class="text-center mt-2">
                    <base-button type="success" @click="finalCreateAccount">
                        I Agree
                    </base-button>
                    <base-button type="danger" @click="cancelAgreement">
                        Cancel
                    </base-button>
                </div>
            </form>
        </modal>
        <modal :show.sync="showPaymentOption">
            <h4 slot="header" class="modal-title mb-0" style="color: #444c57">Payment</h4>
            <credit-card v-if="showPaymentOption" :address="company.address_1" :city="company.city" :monthlyAmount="total_cost" :specialCourseFlag="(checked_coursefolders.length === 0) ? true : false" :state="company.state" :yearlyAmount="perYearCost" :zip="company.zip" type="company" v-on:payClicked="payClicked"/>
        </modal>

        <modal :show.sync="paymentModalMessage">
          <h1 class="text-capitalize text-center text-strong m-0 payment-modal-text text-danger font-weight-500">Your payment is processing...</h1>
        </modal>

        <the-footer/>
    </div>
</template>
<script>
import "sweetalert2/src/sweetalert2.scss";
import '@/orla/partials/style.css';
import '@/orla/partials/responsive.css';

import TheHeader from '@/orla/partials/Header.vue';
import TheFooter from '@/orla/partials/Footer.vue';
import Agreement from "@/views/Pages/Agreement.vue";
import CreditCard from "@/orla/partials/CreditCard";
import Swal from "sweetalert2/dist/sweetalert2.js";
import LoadingIcon from "@/orla/partials/LoadingIcon";
import FoodHandler from './FoodHandler.vue';

export default {
    components: {
        TheHeader,
        TheFooter,
        Agreement,
        CreditCard,
        LoadingIcon,
        FoodHandler
    },
    data: function () {
        return {
            loading: false,
            paymentModalMessage: false,
            currentStep: 0,
            maximumStep: 3,
            company: {
                first_name: "",
                last_name: "",
                company_type: "",
                parent_company: "",
                name: "",
                administrator: "",
                no_of_locations: "",
                no_of_employees: "",
                address_1: "",
                city: "",
                state: "",
                zip: "",
                logo: "",
                telephone_no: "",
                email: "",
                password: "",
                website: "",
            },
            companyErrorsStep1: {
                first_name: "",
                last_name: "",
                name: "",
                no_of_locations: "",
                no_of_employees: "",
                telephone_no: "",
                email: "",
                password: "",
            },
            companyErrorsStep2: {
                address_1: "",
                city: "",
                state: "",
                zip: "",
            },
            coursefolders: [],
            courses: [],
            checked_coursefolders: [],
            checked_courses: [],
            checked_other_courses: [],
            already_member: false,
            member_id: '',
            company_types: [],
            agreementModal: false,
            showPaymentOption: false,
            total_cost: 0,
            perYearCost: 0,
            isMoreCompanyFiledsVisible: false,
            selectedItems: [],
            promocode: "",
            discountPercentage: 0,
        };
    },
    created: function () {
        this.getCourses();
        this.getCompanyTypes();
    },
    methods: {
        previousStep: function () {
            if (this.currentStep == 0) {
                this.$router.push("/orla-signup");
                return false;
            }
            this.currentStep--;

            this.doWeHaveToShowMoreCompanyFields();
        },
        checkifOrlamember(){
            if (!this.already_member) {
                Swal.fire({
                    title: "Already an ORLA member?",
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-success btn-fill",
                    cancelButtonClass: "btn btn-danger btn-fill",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    buttonsStyling: false,
                    icon: "question",
                }).then((result) => {
                    if (result.value) {
                        this.inputStyle = "border:2px solid #f8981c;";
                        this.already_member = true;
                    } else {
                        this.nextStep();
                    }
                });
            } else {
                this.nextStep();
            }
           
        },
        nextStep: async function () {
            if ((this.currentStep + 1) > this.maximumStep) {
                return false;
            }

            if (this.currentStep == 1) {
                let hasError = this.performStepOneValidations();
                if (hasError) {
                    return false;
                }
                this.getSelectedItems();
                await this.calculatePrice();
            }

            this.currentStep++;

            this.doWeHaveToShowMoreCompanyFields();

            this.loading = false;
        },
        acceptNumber: function () {
            var x = this.company.telephone_no
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.company.telephone_no = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        performStepOneValidations: function () {
            let hasError = false;
            let stepOneKeys = [
                'first_name',
                'last_name',
                'name',
                'no_of_locations',
                'no_of_employees',
                'telephone_no',
                'email',
            ];
            stepOneKeys.forEach((keyName) => {
                this.companyErrorsStep1[keyName] = false;
                if (this.company[keyName] == '') {
                    hasError = true;
                    this.companyErrorsStep1[keyName] = true;
                }
            });
            return hasError;
        },
        performStepThreeValidations: function () {
            let hasError = false;
            let stepThreeKeys = [
                'address_1',
                'city',
                'state',
                'zip',
            ];
            stepThreeKeys.forEach((keyName) => {
                this.companyErrorsStep2[keyName] = false;
                if (this.company[keyName] == '') {
                    hasError = true;
                    this.companyErrorsStep2[keyName] = true;
                }
            });
            return hasError;
        },
        getCourses: function () {
            this.$http.get("orla/discountRules").then(response => {
                for (let course of response.data.courses) {
                    let obj = {
                        checked: false,
                        id: course.id,
                        course_name: course.name,
                        member_price: course.member,
                        non_member_price: course.non_member,
                        course_type: course.course_type,
                        cost: course.cost
                    };
                    this.courses.push(obj);
                }
                this.course = this.courses;
                for (let coursefolder of response.data.course_folders) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        member_price: coursefolder.member,
                        non_member_price: coursefolder.non_member,
                        attachedCourses: coursefolder.active_courses
                    };

                    this.coursefolders.push(obj1);
                }
            });
        },
        formatPrice: function (value) {
            return (
                "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            );
        },
        showAgreement: function () {
            this.formData = {
                company_name: this.company.name,
                first_name: this.company.first_name,
                last_name: this.company.last_name,
                company_location_num: this.company.no_of_locations,
                company_employee_num: this.company.no_of_employees,
                company_address_1: this.company.address_1,
                company_address_2: this.company.address_2,
                company_phone: this.company.telephone_no,
                company_email: this.company.email,
                company_zip: this.company.zip,
                website: this.company.website,
                company_type: this.company.company_type,
                username: this.company.email,
                parent_id: this.company.parent_company,
                image: this.image,
                company_city: this.company.city,
                company_state: this.company.state,
                company_password: this.company.password,
                course_ids: this.checked_courses,
                courseFolders: this.checked_coursefolders,
                otherCourseFolders: this.checked_other_courses,
                already_member: this.already_member,
                member_id: this.member_id,
                status: 1,
                payment: [],
                card_info: [],
                i_agree: true,
            };
            this.agreementModal = true;
        },
        applyPromoCode: async function () {
            await this.calculatePrice();
            this.loading = false;
        },
        calculatePrice: async function () {
            if (this.company.no_of_employees >= 201) {
                return Swal.fire({
                    title: "Attention!",
                    html: "Please contact our sales team at <a href='mailto:orla@train321.com'>orla@train321.com</a> for special pricing.",
                    icon: "warning",
                    confirmButtonClass: "btn btn-success btn-fill",
                    confirmButtonText: "OK",
                });
            }

            this.loading = true;

            let data = {
                company_name: this.company.name,
                first_name: this.company.first_name,
                last_name: this.company.last_name,
                number_of_locations: this.company.no_of_locations,
                number_of_employees: this.company.no_of_employees,
                email: this.company.email,
                phone_num: this.company.telephone_no,
                user_type: "corporate",
                already_member: this.already_member,
                member_id: this.member_id,
                course_ids: this.checked_courses,
                course_folders: this.checked_coursefolders,
                other_courses: this.checked_other_courses,
                promocode: this.promocode,
            };
            await this.$http.post("orla/lead", data).then((resp) => {
                this.lead_id = resp.data.user_id;
                this.companyEstimateDetailModel = false;
                this.total_cost = resp.data.perYearCost;
                this.sub_total = resp.data.perYearCost;
                this.employees_count = resp.data.number_of_employees;
                this.locations_count = resp.data.number_of_locations;
                this.showPricePlan = true;
                this.perYearCost = resp.data.sub_total;
                this.yearDiscount = resp.data.yearDiscount;
                this.discount = resp.data.discount;
                this.onlySexualCourse = resp.data.only_sexual_course;
                promocode: this.promocode,
                this.loading = false;
                this.discountPercentage = resp.data.discountPercentage;
            }).catch((error) => {
                // this.currentStep--;
                this.loading = false;
                this.paymentModalMessage = false;
                return Swal.fire({
                    title: "Error!",
                    html: error.response.data.message,
                    icon: "error",
                });
            });
        },
        signupAgreement: function () {
            let hasError = this.performStepThreeValidations();
            if (hasError) {
                return false;
            }
            this.showAgreement();
        },
        cancelAgreement: function () {
            this.agreementModal = false;
        },
        finalCreateAccount: function () {
            this.agreementModal = false;
            this.showPaymentOption = true;
        },
        payClicked: function (cardData) {
            this.loading = true;
            this.paymentModalMessage = true;
            let payment = {
                payment_type: "",
                cardholder_street_address: cardData.address + "," + cardData.city + "," + cardData.state,
                cardholder_zip: cardData.zip,
                transaction_amount: "",
                token: cardData.token,
            };
            if (this.onlySexualCourse) {
                payment.payment_type = "one-time";
            } else {
                payment.payment_type = cardData.paymentType;
            }
            if (cardData.paymentType == "monthly") {
                payment.transaction_amount = this.total_cost.toFixed(2);
            }
            if (cardData.paymentType == "yearly") {
                payment.transaction_amount = this.perYearCost.toFixed(2);
            }
            this.formData.payment = payment;
            this.formData.address_1 = cardData.address;
            this.formData.company_address_1 = cardData.address;
            this.formData.company_state = cardData.state;
            this.formData.company_city = cardData.city;
            this.formData.company_zip = cardData.zip;
            this.createAccount(this.formData);
        },
        createAccount(formDataSubmitted) {
            delete this.$http.defaults.headers["authorization"];
            this.creatAccountClicked = true;
            this.$http.post("orla/register", formDataSubmitted).then((resp) => {
                this.loading = false;
                let ids = [];
                let obj = {
                    id: resp.data.id,
                };
                ids.push(obj);
                this.showPaymentOption = false;
                this.$http.post("company/welcome_email", {
                    form_data: formDataSubmitted,
                    password: this.company.password,
                    ids: ids,
                }).then((resp) => {
                    this.loading = false;
                    this.paymentModalMessage = false;
                    this.$router.push("/orla-login");
                    this.agreementModal = false;
                    Swal.fire({
                        title: "Success!",
                        text: `Account created successfully.`,
                        icon: "success",
                    });
                }).catch((error) => {
                    this.loading = false;
                    this.paymentModalMessage = false;
                    this.showPaymentOption = false;
                    this.$router.push("/orla-login");
                    return Swal.fire({
                        title: "Success!",
                        text: "Account created successfully.",
                        icon: "success",
                    });
                });
            }).catch((error) => {
                this.loading = false;
                this.paymentModalMessage = false;
                this.showPaymentOption = false;
                return Swal.fire({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                });
            });
        },
        getCompanyTypes: function () {
            this.$http.post("company/company_dropdown_data", {}).then((resp) => {
                for (let type of resp.data.companytype) {
                    let obj = {
                        type: type.type,
                        id: type.id,
                    };
                    this.company_types.push(obj);
                }
            });
        },
        onImageChange: function (event) {
            let files = event;
            if (!files.length) return;
            this.createImage(files[0]);
        },
        createImage: function (file) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        courseFolderSelected: function (event) {
            this.checked_coursefolders = [];
            if (event.target.checked) {
                this.coursefolders.forEach(courseFolder => {
                    this.checked_coursefolders.push(courseFolder.id);
                });
            }
        },
        courseSelected: function (event) {
            this.checked_courses = [];
            if (event.target.checked) {
                this.courses.forEach(course => {
                    this.checked_courses.push(course.id);
                });
            }
        },
        showMoreCompanyFields: function () {
            this.isMoreCompanyFiledsVisible = true
            if (this.currentStep == 2) {
                Swal.fire({
                    title: "Information!",
                    text: "Please complete the details below to finalize your company account",
                    icon: "info"
                }).then(result => {
                    // this.isMoreCompanyFiledsVisible = true
                });
            }
        },
        doWeHaveToShowMoreCompanyFields: function () {
            if (this.currentStep < 2) {
                this.isMoreCompanyFiledsVisible = false;
            }
        },
        getSelectedItems: function () {
            console.clear();
            this.selectedItems = [];
            this.coursefolders.forEach(courseFolder => {
                if (this.checked_coursefolders.includes(courseFolder.id)) {
                    this.selectedItems.push({
                        name: courseFolder.coursefolder_name,
                        price: this.already_member ? courseFolder.member_price : courseFolder.non_member_price,
                        id: courseFolder.id,
                        type: 'courseFolder',
                    });
                }
            });
            this.courses.forEach(course => {
                if (this.checked_courses.includes(course.id)) {
                    this.selectedItems.push({
                        name: course.course_name,
                        price: this.already_member ? course.member_price : course.non_member_price,
                        id: course.id,
                        type: 'course'
                    });
                }
            });
        },
        removeCourse: async function (item) {
            this.loading = true;
            switch (item.type) {
                case  'courseFolder':
                    this.checked_coursefolders = [];
                    break;
                case  'course':
                    this.checked_courses = [];
                    break;
            }
            this.getSelectedItems();
            await this.calculatePrice();
            this.loading = false;
        }
    },
};
</script>

<style scoped>
    .pricing-table-heading.heading {
        font-weight: bold;
        font-size: 28px;
        text-decoration: underline;
    }
</style>
